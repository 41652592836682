import { ReactNode } from 'react'

import { Carousel } from '@/components/styleguide/Homepage/Carousel'

export type AffiliateCardsProps = {
  children: ReactNode
  header?: ReactNode
  width: 'fixed' | 'content'
}

export const AffiliateCards = ({ children, header, width }: AffiliateCardsProps) => {
  return (
    <Carousel
      width={width}
      scrollValue={200}
      header={header}
      sectionName="affiliates">
      {children}
    </Carousel>
  )
}
