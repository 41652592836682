import Image from 'next/image'

import { constants } from '@/lib/constants'
import { Size } from '@/types/system'

const logoSizes = {
  sellYourVehicle: {
    sm: {
      width: 74,
      height: 14,
    },
    md: {
      width: 101,
      height: 20,
    },
  },
  insurance: {
    sm: {
      width: 74,
      height: 14,
    },
    md: {
      width: 101,
      height: 20,
    },
  },
}

export const Logo = ({
  partner,
  size,
}: {
  partner: keyof typeof logoSizes
  size: Size.Small | Size.Medium
}) => {
  return (
    <Image
      alt={`${partner} logo`}
      width={logoSizes[partner][size].width}
      height={logoSizes[partner][size].height}
      src={`${constants.imageHost.assets}/affiliate/logo/${constants.partners[partner]}.svg`}
      draggable={false}
    />
  )
}
