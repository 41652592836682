import { useDispatch } from 'react-redux'

import { constants } from '../../../../lib/constants'
import { addToDataLayer } from '../../../../lib/utilities/vehicle'
import { postAnalyticsEvent } from '../../../../store/actions/event'
import { ButtonHierarchy } from '@/components/styleguide/Button'
import { AffiliateCard } from '@/components/styleguide/Card/Affiliate/AffiliateCard'
import { ExternalLink } from '@/components/styleguide/Link'
import { Size, Theme } from '@/types/system'

export type ChargingCardProps = {
  chargingCampaign: string
  page: string
  position: string
}

export const ChargingCard = ({ chargingCampaign, page, position }: ChargingCardProps) => {
  const dispatch = useDispatch()

  const trackClick = () => {
    const buttonPosition = `${position}-${page}`
    addToDataLayer({
      event: constants.eventTypes.getChargerQuote.toLowerCase().split('_').join('-'),
      action: 'button-click',
      position: buttonPosition,
    })

    dispatch(
      postAnalyticsEvent({
        event: constants.eventTypes.getChargerQuote,
        path: page,
        payload: {
          position: buttonPosition,
        },
      }),
    )
  }

  return (
    <AffiliateCard
      partner="charging"
      title=""
      paragraph=""
      theme={Theme.dark}>
      <ExternalLink
        hasPadding
        hasBackground
        size={Size.Medium}
        hierarchy={ButtonHierarchy.Filled}
        theme={Theme.dark}
        onClick={trackClick}
        href={`${chargingCampaign}`}>
        Learn more
      </ExternalLink>
    </AffiliateCard>
  )
}
