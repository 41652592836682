import { useDispatch } from 'react-redux'

import styles from './affiliate.module.scss'
import { AffiliateCard } from '@/components/styleguide/Card/Affiliate/AffiliateCard'
import { constants } from '@/lib/constants'
import { useExperiment } from '@/lib/hooks/useExperiment'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { postAnalyticsEvent } from '@/store/actions/event'
import { Theme } from '@/types/system'

export type SellYourVehicleCardProps = {
  isVans: boolean
  page: string
  position: string
  isEnquiredPage?: boolean
}
export const SellYourVehicleCard = ({
  page,
  position,
  isVans = false,
  isEnquiredPage = false,
}: SellYourVehicleCardProps) => {
  const dispatch = useDispatch()
  const [experimentGroup] = useExperiment()

  const trackClick = () => {
    const buttonPosition = `${position}-${page}`
    addToDataLayer({
      event: constants.eventTypes.getSellYourVehicleQuote.toLowerCase().split('_').join('-'),
      action: 'button-click',
      position: buttonPosition,
      experimentGroup,
    })

    dispatch(
      postAnalyticsEvent({
        event: constants.eventTypes.getSellYourVehicleQuote,
        path: page,
        payload: {
          position: buttonPosition,
        },
      }),
    )
  }

  return (
    <AffiliateCard
      onClick={trackClick}
      isVans={isVans}
      partner="sellYourVehicle"
      href={`${constants.sellYourVehicleUrl.dealPage}${
        isVans
          ? `/sell-my-van?utm_source=leaseloco&utm_medium=affiliate&utm_campaign=${position}-${page}`
          : `/partner/leaseloco?utm_source=leaseloco&utm_medium=affiliate&utm_campaign=${position}-${page}`
      }`}
      title={
        isEnquiredPage
          ? `Need to value your ${isVans ? 'van' : 'car'}?`
          : `Value your ${isVans ? 'van' : 'car'} the more money way.`
      }
      shortenedTitle={`Looking to value your ${isVans ? 'van' : 'car'}?`}
      paragraph={`Get your best price from 5,000+ dealers. It's online, fast and 100% free. 84% of Motorway customers get more money.<sup>1</sup>`}
      shortenedParagraph={`5,000+ dealers compete to buy your ${isVans ? 'van' : 'car'}. 84% of customers get more money with Motorway.`}
      theme={Theme.light}>
      <span className={styles['external-button']}>Value your {isVans ? 'van' : 'car'}</span>
    </AffiliateCard>
  )
}
